import { get, put , post} from '../apiService';

export const getWorkouts = (queryParams) => {
    return get(`/workout/${queryParams}`);
};

export const getWorkoutById = (id) => {
    return get(`/workout/${id}`);
};

export const getSavedWorkouts = () => {
    return get('/user/saved-workouts')
}

export const addWorkoutToSaved = id => {
    return put('/user/saved-workouts', {id, action: 'ADD'})
}

export const removeWorkoutFromSaved = id => {
    return put('/user/saved-workouts', {id, action: 'REMOVE'})
}

export const rateWorkout = (userRate, day, workoutId) => {
    return post('/progress/save-user-rate', {userRate, day, workoutId})
}

export const changeDifficulty = (action) => {
    return post('/progress/change-difficulty', {action})
}
