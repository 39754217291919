import './types';
import constModal from './types';
import DefaultModal from './component/defaultModal/defaultModal';
import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {closeModal} from './redux/modalActions';
import {useEffect} from 'react';
import GetBeforeVideoModal from './component/getBeforeVideoModal/getBeforeVideoModal';
import TimeVideoModal from './component/timeVideoModal/timeVideoModal';
import VideoModal from './component/videoModal/videoModal';
import InfoModal from './component/infoModal/infoModal';
import CardManagerModal from "./component/cardManagerModal/cardManagerModal";
import { RatePopUp } from './component/ratePopup/ratePopup';

const Modal = () => {
    const {typeModal, dataModal, statusModal} = useSelector(
        store => store.modalReducer,
    );
    const dispatch = useDispatch();
    const _onClose = () => {
        dispatch(closeModal());
    };
    useEffect(() => {
        if (statusModal) {
            setTimeout(() => {
                document.body.style.overflow = 'hidden';
            }, 0);
        } else {
            setTimeout(() => {
                document.body.style.overflow = 'auto';
            }, 0);
        }
    }, [statusModal]);

    const getWindow = () => {
        switch (typeModal) {
            case constModal.RATE_MODAL:
                return <RatePopUp onClose={_onClose} data={dataModal}/>
            case constModal.CARD_MANAGER_MODAL:
                return <CardManagerModal onClose={_onClose} data={dataModal}/>
            case constModal.DEFAULT_MODAL:
                return <DefaultModal onClose={_onClose} data={dataModal}/>;
            case constModal.BEFORE_VIDEO_MODAL:
                return <GetBeforeVideoModal onClose={_onClose} data={dataModal}/>;
            case constModal.TIME_VIDEO_MODAL:
                return <TimeVideoModal onClose={_onClose} data={dataModal}/>;
            case constModal.VIDEO_MODAL:
                return <VideoModal onClose={_onClose} data={dataModal}/>;
            case constModal.INFO_MODAL:
                return <InfoModal onClose={_onClose} data={dataModal}/>;
            default:
                return null;
        }
    };
    return (
        <div className={statusModal ? 'modal' : 'modal-close'}>{getWindow()}</div>
    );
};
export default Modal;
