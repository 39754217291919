import { setDataModal, setModalType } from './modalActions';
import constModal from '../types';

export const openModal = t => dispatch => {
  const { type = '', data } = t;
  if (type && Object.keys(constModal).includes('DEFAULT_MODAL')) {
    dispatch(setDataModal(data));
    dispatch(setModalType(type));
  } else {
    console.error('type ', type, ' is not correct');
  }
};
