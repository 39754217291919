const constModal = {
  DEFAULT_MODAL: 'DEFAULT_MODAL',
  BEFORE_VIDEO_MODAL: 'BEFORE_VIDEO_MODAL',
  TIME_VIDEO_MODAL: 'TIME_VIDEO_MODAL',
  VIDEO_MODAL: 'VIDEO_MODAL',
  INFO_MODAL: 'INFO_MODAL',
  CARD_MANAGER_MODAL: 'CARD_MANAGER_MODAL',
  RATE_MODAL: 'RATE_MODAL'
};
export default constModal;
