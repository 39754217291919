import './styles.scss';
import { useEffect, useState } from 'react';
import { Button } from '../../../ui-core';

import closeIcon from '../../../../assets/images/myPlan/imgClose.png'

const GetBeforeVideoModal = ({ data, onClose }) => {
    const { _continue, _updatePlan, currentDay, selectedDay } = data
    const [askAgainFlag, setAskAgainFlag] = useState(true);

    useEffect(() => {
        const storedAskAgainFlag = localStorage.getItem('update-plan-ask-flag')

        if (storedAskAgainFlag) {
            if (storedAskAgainFlag === 'true') {
            } else if (storedAskAgainFlag === 'false') {
                setAskAgainFlag(false)
            } else localStorage.setItem('update-plan-ask-flag', 'true')
        } else localStorage.setItem('update-plan-ask-flag', 'true')
    }, [])

    const continueClickHandler = () => {
        localStorage.setItem('update-plan-ask-flag', `${askAgainFlag}`);

        if (!askAgainFlag) {
            localStorage.setItem('update-plan-ask-action', 'continue')
        }

        _continue();
        onClose();
    };

    const updateClickHandler = () => {
        localStorage.setItem('update-plan-ask-flag', `${askAgainFlag}`);

        if (!askAgainFlag) {
            localStorage.setItem('update-plan-ask-action', 'updatePlan')
        }

        _updatePlan();
        onClose();
    };

    const showAgainCheckboxHandler = ({ target: { checked } }) => {
        setAskAgainFlag(!checked);
    };

    const closeModal = () => {
        onClose();
    }


    return (
        <div className='before-video'>
            <img onClick={closeModal} className='before-video__close' src={closeIcon} alt={'close'}/>
            <p className='before-video-title'>Change workout day ?</p>
            <p className='before-video-description'>
                You are currently on Day {currentDay} of your plan. <br/> Would you like to update your plan <br /> to continue from here?</p>
            <div className='before-video__buttons_content'>
                <Button onClick={continueClickHandler}>Keep Current</Button>
                <Button onClick={updateClickHandler}>Change Day</Button>
            </div>
            <label className={'before-video__checkbox'}>
                <small>Don't ask again</small>
                <input type={'checkbox'} checked={!askAgainFlag} onChange={showAgainCheckboxHandler} />
                <span />
            </label>
        </div>
    );

    /*return (
        <div className='before-video'>
            <p className='before-video-title'>You are currently on Day {currentDay} of your plan.</p>
            <p className='before-video-description'>
                Would you like to update your plan <br /> to continue from here?</p>
            <div className='before-video__buttons_content'>
                <Button onClick={continueClickHandler}>Continue</Button>
                <Button onClick={updateClickHandler}>Update plan</Button>
            </div>
            <label className={'before-video__checkbox'}>
                <small>Dont ask again</small>
                <input type={'checkbox'} checked={!askAgainFlag} onChange={showAgainCheckboxHandler} />
                <span />
            </label>
        </div>
    );*/
};
export default GetBeforeVideoModal;
